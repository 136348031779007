<template>
  <PageWithLayout>
    <Search
      :searchDataList="viewModel.searchDataList"
      :searchParams.sync="viewModel.searchParams"/>
    <Board
      :boardData="viewModel.boardData"
      :searchParams.sync="viewModel.searchParams"
      :dataList="viewModel.dataList"
      :paginationData="viewModel.paginationData"
      :foldedDataList.sync="viewModel.foldedDataList"
      @onClickRow="rowId => viewModel.onClickRow(rowId)"
      @onSearch="viewModel.onSearch()">
      <template v-slot:headRight>
        <Button
          btnSize="small"
          btnStyle="primary"
          text="FAQ 추가"
          routeName="SERVICE_FAQ_REGISTER">
          <IconSvg iconName="add" :size="12" iconColor="#fff" />
        </Button>
      </template>
    </Board>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Search from '@lemontree-ai/lemontree-admin-common-front/components/common/search/Search';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
// viewModel
import FaqListViewModel from '@/views/service/faq/viewModel/FaqListViewModel'
import FaqCategoryViewModel from '@/views/service/faq/viewModel/FaqCategoryViewModel'

export default {
  name:'Faq',
  components:{
    PageWithLayout,
    Search,
    Board,
    Button,
    IconSvg,
  },
  beforeMount(){
    this.viewModel.init(this.$route.query);
    const setCategorySelectData = (dataList) => {
      this.viewModel.searchDataList[0].dataList = dataList;
    }
    this.categoryViewModel.init(setCategorySelectData);
  },
  data(){
    return{
      viewModel: new FaqListViewModel(),
      categoryViewModel: new FaqCategoryViewModel(),
    }
  },
}
</script>

<style scoped>
.board_comm::v-deep .fold .tit_question{display:block;padding-bottom:9px;line-height:20px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;}
.board_comm::v-deep .fold .txt_tbl{display:block;overflow:hidden;height:29px;margin:9px 0 0;line-height:22px;}
.board_comm::v-deep .tr_fold .txt_tbl{height:auto;margin-bottom:9px;overflow:inherit;white-space:inherit;text-overflow:inherit}
.board_comm::v-deep .tr_fold .tit_question{overflow:visible;white-space:inherit;text-overflow:inherit;color:#222}
.board_comm::v-deep .tr_fold .editor_view p{min-height:22px;font-size:14px;line-height:22px;color:#666}
/* .board_comm::v-deep .icon_firfin{margin-top:10px}
.board_comm::v-deep .tr_fold .icon_firfin{transform:scaleY(-1)} */
@media (prefers-color-scheme: dark) {
  .board_comm::v-deep .tr_fold .tit_question{color:#fff}
}
/* 모바일 */
@media all and (max-width:1240px){
  .board_comm::v-deep .fold .txt_tbl{margin:0;padding:0}
}
</style>