import router from '@/router';
import { GET_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import apiPath from '@/services/apiPath';

export default class FaqListViewModel {
  constructor() {
    this.searchDataList = [
      {
        title:'카테고리',
        type: 'SelectboxInput',
        dataList:[],
        isFull:true,
        value1:null,
        value2:'',
      },
    ]
    this.boardData = {
      title:'FAQ 목록',
      drawDataList: [
        {
          title:'No.',
          width:'60px',
          value:'id',
          isSorting: true
        },
        {
          title:'카테고리',
          width:'120px',
          value:'categoryName',
          isAlignLeft: true,
          isEllip: true
        },
        {
          title:'Q&A',
          width:'',
          value:'question',
          valueCustom:[
            { 
              type: 'text',
              value: '<strong class="tit_question">'
            },
            {
              type: 'value',
              value:'question',
            },
            { 
              type: 'text',
              value: '</strong>'
            },
            { 
              type: 'text',
              value: '<div class="editor_view">'
            },
            {
              type: 'value',
              value:'answer',
            },
            { 
              type: 'text',
              value: '</div>'
            },
          ],
          // filter:{
          //   name:'convertLineFeed'
          // },
          class: {
            name: 'fold'
          },
          isAlignLeft: true
        },
        {
          title:'편집',
          width:'76px',
          value:'',
          button: { text:'편집', size:'small', style:'secondary_border', onClickEvent: (data) => this.onClickEdit(data) },
        },
      ],
      option:{
        isTotal: true,
        isSize: true
      }
    };
    this.dataList = [];
    this.foldedDataList = [];
    this.searchParams = {
      page:0,
      pageSize:10,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
  }
  init(query){
    const { pageSize, page } = query;
    if(pageSize) {
      this.searchParams.pageSize = Number(pageSize);
    }
    if(page) {
      this.searchParams.page = Number(page);
    }
    this.getData();
  }
  onSearch(){
    this.getData();
  }
  onClickRow(rowId){
    const isFolded = this.foldedDataList.length > 0 && this.foldedDataList.find(item => item === rowId);
    if(isFolded){
      this.foldedDataList = this.foldedDataList.filter(item => item != rowId);
    }else{
      this.foldedDataList.push(rowId);
    }
  }
  onClickEdit(data){
    router.push({
      name: 'SERVICE_FAQ_MODIFY',
      params: { id : data.id },
      query : {
        pageSize : this.searchParams.pageSize,
        page : this.searchParams.page,
      }
    });
  }
  getData(){
    const query = makeQueryStringByObject(this.searchParams);
    const path = `${apiPath.FAQ_LIST}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.dataList = resultData.list;
      this.paginationData.totalCount = resultData.total;
      this.paginationData.totalPage = resultData.totalPage;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}